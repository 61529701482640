import cn from "classnames";
import s from "./AuthLayout.module.scss";
import Logo from "assets/images/logo-dark.svg";
import { Cookies } from "react-cookie";
import { DashboardsRoute } from "const";
import { Navigate } from "react-router-dom";

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const cookies = new Cookies();
  const isAuth = cookies.get("token");

  if (isAuth) {
    return <Navigate to={DashboardsRoute.SECURITIZATION} />;
  }

  return (
    <div className={s.layout}>
      <main className={cn(s.main, s.col)}>
        <div className={s.content}>
          <header className={s.header}>
            <img src={Logo} className={s.logo} alt="Kingsbridge logo" />
            <strong className={s.logoText}>HEI EDGE</strong>
          </header>
          {children}
        </div>
      </main>
      <aside className={cn(s.aside, s.col)}>
        <h1 className={s.heading}>
          Welcome&nbsp;to <br /> Kingsbridge&nbsp;HEI
          <br />
          Management Platform!
        </h1>
      </aside>
    </div>
  );
};
