import { Cookies } from "react-cookie";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Layout } from "components/layout/Layout/Layout";
import { useAppDispatch } from "./hooks/reduxHooks";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "routes";

import { getUser } from "store/actions/UserActions";
import { useEffect } from "react";
import { NotFound } from "components/pages/NotFound/NotFound";
import { removePersistors } from "utils/common";
import { AuthRoute, DashboardsRoute, NotFoundRoute } from "const/routes";
import { AuthLayout } from "components/layout/AuthLayout/AuthLayout";

interface RouteType {
  path: string;
  element: JSX.Element;
  subRoutes?: RouteType[];
  id: number;
}

const App = () => {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cookies.get("token") && cookies.get("email")) {
      dispatch(getUser({ email: cookies.get("email") }));
    }
  }, []);

  const renderRoutes = (routes: RouteType[]) => {
    removePersistors();
    return routes.map(({ path, element, subRoutes, id }) => (
      <Route path={path} element={element} key={id}>
        {subRoutes?.length ? renderRoutes(subRoutes) : null}
      </Route>
    ));
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        {renderRoutes(PRIVATE_ROUTES)}
        <Route path={NotFoundRoute} element={<NotFound />} />
        <Route
          index
          element={<Navigate to={DashboardsRoute.SECURITIZATION} />}
        />
        <Route path="*" element={<Navigate to={NotFoundRoute} />} />
      </Route>

      <Route
        path="/"
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        {renderRoutes(PUBLIC_ROUTES)}
        <Route path={NotFoundRoute} element={<NotFound />} />
        <Route index element={<Navigate to={AuthRoute.LOGIN} />} />
        <Route path="*" element={<Navigate to={NotFoundRoute} />} />
      </Route>
    </Routes>
  );
};

export default App;
