import { Icon } from "components/common/Icon/Icon";
import { forwardRef } from "react";
import Select, { components } from "react-select";

type Option = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  options: string[];
  value: string;
  onSelect: (val: string) => void;
  onInputChange?: (val: string) => void;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
};

export const SelectReact = forwardRef<any, Props>(
  (
    { name, options, value, onSelect, onInputChange, disabled, isSearchable, isClearable },
    ref
  ) => {
    const selectOptions = options?.map((i: string) => ({ value: i, label: i }));

    const getListOption = (list: Option[], val: string) => {
      return list.find(({ value }) => value === val) || "";
    };

    const DropdownIndicator = (props: any) => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon icon="down" className="r-dropdownBtn" />
        </components.DropdownIndicator>
      );
    };

    const ClearIndicator = (props: any) => {
      return (
        <components.ClearIndicator {...props}>
          <Icon icon="close" className="r-clearBtn" />
        </components.ClearIndicator>
      );
    };

    return (
      <Select
        unstyled
        placeholder={isSearchable ? "Enter data" : "Select"}
        className="basic-single"
        classNamePrefix="select"
        isLoading={false}
        isClearable={isClearable}
        isSearchable={Boolean(isSearchable)}
        name={name}
        options={selectOptions}
        value={getListOption(selectOptions, value)}
        onChange={(val: any) => onSelect(val?.value)}
        onInputChange={(val: any) => onInputChange && onInputChange(val)}
        components={{
          DropdownIndicator,
          ClearIndicator,
          IndicatorSeparator: null,
        }}
        classNames={{
          control: () =>
            `select r-select r-select-opener ${disabled && "disabled"}`,
          placeholder: () => "r-select-placeholder",
          menu: () => "r-select-menu",
          option: () => "r-select-option",
        }}
        isDisabled={disabled}
        noOptionsMessage={() => "Loading..."}
        ref={ref}
      />
    );
  }
);
