import { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { sanitizeFilename } from "../utils";
import { fetchWithAuth } from "../utils/fetch-with-auth";

interface UsePostQueryType<BodyData> {
  post: (
    data: BodyData,
    queryParams?: string,
    pageFor?: string,
    pageData?: string
  ) => Promise<Response>;
  postFormData: (
    data: FormData,
    queryParams?: string,
    pageFor?: string,
    pageData?: any
  ) => Promise<Response>;
  loading: boolean;
  error: string | null;
  responseData: any;
  responseStatus: number | null;
  resetResponseData: () => void;
}

export const usePostQuery = <BodyData, ResponseData>(
  query: string,
  changeStorage?: () => void
): UsePostQueryType<BodyData> => {
  const [cookies] = useCookies(["token"]);
  const [responseData, setResponseData] = useState<ResponseData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [responseStatus, setResponseStatus] = useState<number | null>(null);

  const resetResponseData = () => setResponseData(null);

  const post = useCallback(
    async (
      data: BodyData,
      queryParams?: string,
      pageFor?: string,
      pageData?: any
    ) => {
      try {
        if (pageFor) {
          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: true,
              error: false,
              fileName: pageData,
            })
          );
        }
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
          authorization: `Bearer ${cookies.token}`,
        };
        const response = await fetchWithAuth(
          queryParams ? `${query}?${queryParams}` : query,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers,
          }
        );

        if (response.status !== 200) {
          let message;
          let errorTitle = undefined;

          if (response.status === 500) {
            message = response.statusText;
          } else if (response.status === 400) {
            const json = await response.json();
            message = json.message || json.detail[0].msg || json.detail;
            if (json.error !== "Bad request") {
              errorTitle = json.error;
            }
          } else {
            const json = await response.json();
            message = json.message || json.detail[0].msg || json.detail;
          }

          throw new Error(message, {
            cause: { responseStatus: response.status, title: errorTitle },
          });
        }

        const json = await response.json();
        if (json.file_name)
          json.file_name = sanitizeFilename(json.file_name);

        setResponseData(json);
        setResponseStatus(response.status);
        setLoading(false);
        if (pageFor) {
          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: false,
              error: false,
              responseStatus: response.status,
              fileName: json.file_name,
              fileId: json.file_id,
            })
          );
          changeStorage && changeStorage();
        }
        return response;
      } catch (error: any) {
        // setError(message as string)
        if (pageFor) {
          setResponseStatus(error.cause?.responseStatus);
          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: false,
              error: true,
              errorTitle: error?.cause?.title,
              errorMessage: error?.message,
              responseStatus: error.cause?.responseStatus,
              fileName: pageData,
            })
          );
          changeStorage && changeStorage();
        }
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [query]
  );

  const postFormData = useCallback(
    async (
      data: FormData,
      queryParams?: string,
      pageFor?: string,
      pageData?: any
    ) => {
      setResponseStatus(null);

      try {
        if (pageFor) {
          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: true,
              error: false,
              fileName: pageData,
            })
          );
        }
        setLoading(true);
        const headers = {
          authorization: `Bearer ${cookies.token}`,
        };
        const response = await fetchWithAuth(
          queryParams ? `${query}?${queryParams}` : query,
          {
            method: "POST",
            body: data,
            headers,
          }
        );

        if (response.status !== 200) {
          let message;
          if (response.status === 500) {
            message = response.statusText;
          } else {
            const json = await response.json();
            message = json.message || json.detail[0].msg || json.detail;
          }

          throw new Error(message, {
            cause: { responseStatus: response.status },
          });
        }

        const json = await response.json();
        if (json.file_name)
          json.file_name = sanitizeFilename(json.file_name);

        setResponseData(json);
        setResponseStatus(response.status);
        setLoading(false);
        if (pageFor) {
          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: false,
              error: false,
              responseStatus: response.status,
              fileName: json.file_name,
            })
          );
          changeStorage && changeStorage();
        }
        return response;
      } catch (error: any) {
        setError(error?.message as string);
        if (pageFor) {
          setResponseStatus(error.cause?.responseStatus);

          localStorage.setItem(
            pageFor,
            JSON.stringify({
              loading: false,
              error: true,
              errorMessage: error?.message,
              responseStatus: error.cause?.responseStatus,
              fileName: pageData,
            })
          );
          changeStorage && changeStorage();
        }
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [query]
  );

  return {
    responseData,
    loading,
    error,
    post,
    postFormData,
    responseStatus,
    resetResponseData,
  };
};
