export const NotFoundRoute = "not-found";

const auth = "auth";
export const AuthRoute = {
  ROOT: auth,
  LOGIN: `/${auth}/login`,
  FORGOT_PASSWORD: `/${auth}/forgot`,
  FORGOT_PASSWORD_CONFIRMATION: `/${auth}/forgot-password-finish`,
  RESET_PASSWORD_SUCCESS: `/${auth}/reset-password-success`,
};

export const WatchlistRoute = "watchlist";

const dashboards = "dashboards";
export const DashboardsRoute = {
  ROOT: dashboards,
  SECURITIZATION: `/${dashboards}/securitization`,
  PORTFOLIO: `/${dashboards}/portfolio`,
  ALL_MSAS_HPA: `/${dashboards}/all-msas-hpa`,
  ACQUIRED_PORTFOLIO: `/${dashboards}/acquired-portfolio`,
  POINT_BUY_BOX: `/${dashboards}/point-buy-box`,
  PNT2023_2: `/${dashboards}/pnt2023-2`,
  PLEDGE_COLLATERAL: `/${dashboards}/pledge-collateral`,
  EXIT: `/${dashboards}/exit`,
};

const imports = "imports";
export const ImportsRoute = {
  ROOT: imports,
  OON: `/${imports}/oon`,
  DEFAULT_MONITORING: `/${imports}/default-monitoring`,
  DETAIL: `/${imports}/detail`,
  TAPE: `/${imports}/tape`,
  PRELIMINARY_EXITS: `/${imports}/preliminary-exits`,
  ORIGINATION_FEES_SWEEP: `/${imports}/origination-fees-sweep`,
  MONTHLY_COLLECTIONS: `/${imports}/monthly-collections`,
  THIRD_PARTY_REPORTS: `/${imports}/3rd-party-reports`,
  FHFA: `/${imports}/fhfa`,
  TITLE_POLICY: `/${imports}/title-policy`,
  DEMOGRAPHICS_DATA: `/${imports}/demographics-data`,
};

const kasf = "kasf-valuation-model";
export const KasfValuationModelRoute = {
  ROOT: kasf,
  ASSET_LIST: `/${kasf}/asset-list`,
  FORWARD_CASHFLOWS: `/${kasf}/forward-cashflows`,
  OFFICIAL_RUN_SELECTION: `/${kasf}/official-run-selection`,
  KASF_INTERNAL_VALUATION: `/${kasf}/kasf-internal-valuation`,
  THIRD_PARTY_COMPARISON_REPORT: `/${kasf}/3rd-party-comparison-report`,
  INTERNAL_VALUATION_COMPARISON_REPORT: `/${kasf}/internals-valuation-comparison-report`,
  HPA_CONTRACT: `/${kasf}/hpa-contract`,
  HPA_METRO: `/${kasf}/hpa-metro`,
};

const accounting = "accounting";
export const AccountingRoute = {
  ROOT: accounting,
  EXITS_VARIANCE_REPORT: `/${accounting}/exits-variance-report`,
  ACTUAL_RETURNS_REPORT: `/${accounting}/actual-returns-report`,
  IMPARED_LIST: `/${accounting}/impaired-list`,
  MONTHLY_ROLLS: `/${accounting}/monthly-rolls`,
  PAYOFFS_REPORT: `/${accounting}/payoffs-report`,
};

const record = "record-management";
export const RecordManagement = {
  ROOT: record,
  DELETE_CONTRACT_DETAILS: `/${record}/delete-contract-details`,
  PAYOFF_DEMAND: `/${record}/pay-off-demand`,
  PRELIMINARY_EXIT: `/${record}/preliminary-exit`,
  UPDATE_CONTRACT_DETAILS: `/${record}/update-contract-details`,
  LOOK_UP_CONTRACT_DETAILS: `/${record}/look-up-contract-details`,
};

const pledge = "pledge-collateral";
export const PledgeCollateralRoute = {
  ROOT: pledge,
  PLEDGE_DEBT_LINE: `/${pledge}/pledge-debt-line`,
  PLEDGE_LIST: `/${pledge}/pledge-list`,
  SMM_RESOLUTION_RATES: `/${pledge}/smm-resolution-rates`,
};

export const HistoryRoute = "history";

const manageUsers = "manage-users";
export const ManageUsersRoute = {
  ROOT: manageUsers,
  ADD: `/${manageUsers}/add`,
  EDIT: `/${manageUsers}/edit`,
};

export const SettingsRoute = "settings";
