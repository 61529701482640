import { UploadStatus } from "interfaces";
import s from "./UploadNotification.module.scss";
import cn from "classnames";
import { CloseBtn } from "components/common/CloseBtn/CloseBtn";
import Loader from "assets/images/ loader.gif";
import { Button } from "components/common";

type Props = {
  pageName: string;
  pageTitle?: string;
  status: UploadStatus;
  fileName: string | null;
  handleDownload?: () => void;
  closeNotification: () => void;
  disabledDownload?: boolean;
};

export const UploadNotification = ({
  pageName,
  pageTitle,
  status,
  fileName,
  handleDownload,
  closeNotification,
  disabledDownload,
}: Props) => {
  const getErrorMessage = (imports = false) => {
    const message = JSON.parse(
      localStorage.getItem(pageName) || ""
    )?.errorMessage;

    if (!imports) {
      return message
        ? message[0].toUpperCase() + message.slice(1)
        : "Something went wrong";
    }

    const correctText = fileName ? "the file contains" : "files contain";

    return message
      ? `${
          message[0].toUpperCase() + message.slice(1)
        }. Please, ensure that ${correctText} correct data`
      : `Something went wrong. Please, ensure that ${correctText} correct data`;
  };

  const getErrorTitle = (defaultTitle: string) => {
    const error = JSON.parse(
        localStorage.getItem(pageName) || ""
    );
    const errorTitle = error?.errorTitle;

    return errorTitle ?? defaultTitle;
  };

  const statusMapper: any = {
    import: {
      loading: {
        subTitleBold: fileName,
        title: "Your file is importing",
        titleFiles: "Your files are importing",
        description: "This will take a moment",
      },
      done: {
        subTitle: "Your file",
        title: fileName,
        titleFiles: "Your files",
        description: "had been successfully uploaded!",
      },
      failedData: {
        subTitleBold: fileName,
        title: "Data Error",
        description: getErrorMessage(true),
      },
      failed: {
        subTitleBold: fileName,
        title: "Connection Error",
        description:
          "Server doesn't respond... Check your Internet connection and try again",
      },
    },
    download: {
      loading: {
        subTitle: "",
        title: `${pageTitle} is generating`,
        description: "This will take a moment",
      },
      done: {
        subTitle: pageTitle,
        title: fileName,
        description: "had been successfully generated!",
      },
      failedData: {
        title: getErrorTitle("Data Error"),
        description: getErrorMessage(),
      },
      failed: {
        title: "Connection Error",
        description:
          "Server doesn't respond... Check your Internet connection and try again",
      },
    },
  };

  const mapper = handleDownload ? statusMapper.download : statusMapper.import;

  const onClose = () => {
    pageName && localStorage.removeItem(pageName);
    closeNotification();
  };

  return (
    <div className={cn(s.container, s[status])}>
      {status === UploadStatus.loading && (
        <div className={s.loader}>
          <img src={Loader} alt="loader" width="60" height="60" />
        </div>
      )}
      <div className={s.content}>
        <>
          {((mapper[status].subTitle && !mapper[status].titleFiles) ||
            (mapper[status].subTitle && fileName)) && (
            <span className={s.subTitle}>{mapper[status].subTitle}</span>
          )}
          {mapper[status].subTitleBold && (
            <span className={s.subTitleBold}>
              {mapper[status].subTitleBold}
            </span>
          )}
          {((mapper[status].title && !mapper[status].titleFiles) ||
            (mapper[status].title && fileName)) && (
            <span
              className={cn(
                s.title,
                (status === UploadStatus.failed ||
                  status === UploadStatus.failedData) &&
                  s.alert
              )}
            >
              {mapper[status].title}
            </span>
          )}
          {mapper[status].titleFiles && !fileName && (
            <span className={s.titleFiles}>{mapper[status].titleFiles}</span>
          )}
          {mapper[status].description && (
            <p className={s.description}>{mapper[status].description}</p>
          )}
          {handleDownload && status === UploadStatus.done && (
            <Button
              filled="gray"
              size="xs"
              icon="download"
              title="Download"
              onClick={handleDownload}
              className={s.download}
              disabled={disabledDownload}
            />
          )}
        </>
      </div>
      {status !== UploadStatus.loading && <CloseBtn onClose={onClose} />}
    </div>
  );
};
