import { ISection, MenuActions, Roles } from "interfaces";
import {
  AccountingRoute,
  DashboardsRoute,
  HistoryRoute,
  ImportsRoute,
  KasfValuationModelRoute,
  ManageUsersRoute,
  PledgeCollateralRoute,
  RecordManagement,
  SettingsRoute,
} from "./routes";

export const navigation: ISection[] = [
  {
    id: 1,
    nav: [
      {
        id: 1,
        label: "Dashboards",
        link: "",
        icon: "pie-chart",
        action: MenuActions.toggle,
        child: [
          {
            label: "Securitization & Investment Tapes",
            link: DashboardsRoute.SECURITIZATION,
          },
          {
            label: "Portfolio Dashboard",
            link: DashboardsRoute.PORTFOLIO,
          },
          {
            label: "All MSAs HPA",
            link: DashboardsRoute.ALL_MSAS_HPA,
          },
          {
            label: "SP-1",
            link: DashboardsRoute.ACQUIRED_PORTFOLIO,
          },
          {
            label: "Point Buy Box Dashboard",
            link: DashboardsRoute.POINT_BUY_BOX,
          },
          {
            label: "PNT2023-2 Dashboard",
            link: DashboardsRoute.PNT2023_2,
          },
          {
            label: "Pledge Collateral Dashboard",
            link: DashboardsRoute.PLEDGE_COLLATERAL,
          },
          {
            label: "Exit Dashboard",
            link: DashboardsRoute.EXIT,
          },
        ],
      },
      {
        id: 2,
        label: "Imports",
        link: "",
        icon: "imports",
        action: MenuActions.toggle,
        child: [
          {
            label: "Demographics",
            link: ImportsRoute.DEMOGRAPHICS_DATA,
          },
          {
            label: "Default Monitoring",
            link: ImportsRoute.DEFAULT_MONITORING,
          },
          {
            label: "Detail",
            link: ImportsRoute.DETAIL,
          },
          {
            label: "FHFA",
            link: ImportsRoute.FHFA,
          },
          {
            label: "Monthly Collections",
            link: ImportsRoute.MONTHLY_COLLECTIONS,
          },
          {
            label: "OON",
            link: ImportsRoute.OON,
          },
          {
            label: "Origination Fees Sweep",
            link: ImportsRoute.ORIGINATION_FEES_SWEEP,
          },
          {
            label: "Preliminary Exits",
            link: ImportsRoute.PRELIMINARY_EXITS,
          },
          {
            label: "Tape",
            link: ImportsRoute.TAPE,
          },
          {
            label: "Title Policy",
            link: ImportsRoute.TITLE_POLICY,
          },
          {
            label: "3rd Party Reports",
            link: ImportsRoute.THIRD_PARTY_REPORTS,
          },
        ],
      },
      {
        id: 3,
        label: "Valuation",
        link: "",
        icon: "pledge",
        action: MenuActions.toggle,
        child: [
          {
            label: "Asset List",
            link: KasfValuationModelRoute.ASSET_LIST,
          },
          {
            label: "Forward Cashflows",
            link: KasfValuationModelRoute.FORWARD_CASHFLOWS,
          },
          {
            label: "HPA Contract",
            link: KasfValuationModelRoute.HPA_CONTRACT,
          },
          {
            label: "HPA Metro",
            link: KasfValuationModelRoute.HPA_METRO,
          },
          {
            label: "Internal Valuation Comparison Report",
            link: KasfValuationModelRoute.INTERNAL_VALUATION_COMPARISON_REPORT,
          },
          {
            label: "KASF internal valuation",
            link: KasfValuationModelRoute.KASF_INTERNAL_VALUATION,
          },
          {
            label: "Official Run Selection",
            link: KasfValuationModelRoute.OFFICIAL_RUN_SELECTION,
          },
          {
            label: "3rd Party Comparison report",
            link: KasfValuationModelRoute.THIRD_PARTY_COMPARISON_REPORT,
          },
        ],
      },
      {
        id: 4,
        label: "Pledge Collateral",
        link: "",
        icon: "bank",
        action: MenuActions.toggle,
        child: [
          {
            label: "Available to Pledge",
            link: PledgeCollateralRoute.PLEDGE_DEBT_LINE,
          },
          {
            label: "Pledge List",
            link: PledgeCollateralRoute.PLEDGE_LIST,
          },
          {
            label: "SMM and Resolution Rates",
            link: PledgeCollateralRoute.SMM_RESOLUTION_RATES,
          },
        ],
      },
      {
        id: 5,
        label: "Accounting",
        link: "",
        icon: "calculator",
        action: MenuActions.toggle,
        child: [
          {
            label: "Actual Returns Report",
            link: AccountingRoute.ACTUAL_RETURNS_REPORT,
          },
          {
            label: "Exits Variance Report",
            link: AccountingRoute.EXITS_VARIANCE_REPORT,
          },
          {
            label: "Impaired List",
            link: AccountingRoute.IMPARED_LIST,
          },
          {
            label: "Monthly Rolls Report",
            link: AccountingRoute.MONTHLY_ROLLS,
          },
          {
            label: "Payoffs Report",
            link: AccountingRoute.PAYOFFS_REPORT,
          },
        ],
      },
      {
        id: 6,
        label: "Record Management",
        link: "",
        icon: "union",
        action: MenuActions.toggle,
        child: [
          {
            label: "Delete Contract Details",
            link: RecordManagement.DELETE_CONTRACT_DETAILS,
          },
          {
            label: "Look Up Contract Details",
            link: RecordManagement.LOOK_UP_CONTRACT_DETAILS,
          },
          {
            label: "Pay-off Demand",
            link: RecordManagement.PAYOFF_DEMAND,
          },
          {
            label: "Preliminary Exit",
            link: RecordManagement.PRELIMINARY_EXIT,
          },
          {
            label: "Update Contract Details",
            link: RecordManagement.UPDATE_CONTRACT_DETAILS,
          },
        ],
      },
      {
        id: 7,
        label: "History",
        link: HistoryRoute,
        icon: "clock",
        action: null,
        child: null,
      },
    ],
  },
  {
    id: 2,
    nav: [
      {
        id: 0,
        label: "Manage Users",
        link: ManageUsersRoute.ROOT,
        icon: "users",
        action: null,
        child: null,
        permissions: [Roles.Admin],
      },
      {
        id: 1,
        label: "Settings",
        link: SettingsRoute,
        icon: "settings",
        action: null,
        child: null,
      },
      {
        id: 2,
        label: "Log Out",
        link: "",
        icon: "log-out",
        action: MenuActions.logout,
        child: null,
      },
    ],
  },
];
