import { PageContent } from "components/layout/PageContent/PageContent";
import s from "./Users.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { ActionsColumn, Roles } from "interfaces";
import { useNavigate } from "react-router-dom";
import { DashboardsRoute, ManageUsersRoute, manageUsersColumns } from "const";
import { deleteUser, getUsers } from "store/actions/ManageUsersActions";
import { TableControlsWithActionColumn } from "components/modules/TableControls/TableControlsWithActionColumn";
import { Button, Modal } from "components/common";

export const ManageUsers = () => {
  const { user } = useAppSelector((state) => state.user);
  const { users, isLoading } = useAppSelector((state) => state.manageUsers);

  const list = users?.map((user) => ({
    ...user,
    name: `${user.first_name} ${user.last_name}`,
  }));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [idDeleteUser, setIdDeleteUser] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (user && user?.role !== Roles.Admin)
      navigate(`${DashboardsRoute.SECURITIZATION}`, { replace: true });
  }, [user?.role]);

  const handleEdit = (id: string) => {
    navigate(`${ManageUsersRoute.EDIT}/${id}`);
  };

  const handleDelete = (id: string) => {
    setIdDeleteUser(id);
  };

  const onDelete = async () => {
    const data = {
      id: idDeleteUser || "",
      email:
        users?.find(({ id }) => idDeleteUser && id === +idDeleteUser)?.email ||
        "",
    };
    if (data.id && data.email) {
      await dispatch(deleteUser(data));
      dispatch(getUsers());
      setIdDeleteUser(null);
    }
  };

  const renderAddUser = () => (
    <Button
      title="Add User"
      size="xs"
      onClick={() => navigate(`${ManageUsersRoute.ADD}`)}
      uppercase
    />
  );

  return (
    <PageContent title="Manage Users" history={false} search>
      <>
        <TableControlsWithActionColumn
          columnsList={manageUsersColumns}
          list={list || []}
          defaultSortedColumn="name"
          searchColumns={["name"]}
          actionsColumn={[ActionsColumn.edit, ActionsColumn.delete]}
          idRowActionColumn="id"
          onEdit={handleEdit}
          onDelete={handleDelete}
          tableAction={renderAddUser()}
          isLoading={isLoading}
        />
        <Modal
          title="Do you really want to delete this user? "
          subtitle="You can't undo this action"
          isOpen={Boolean(idDeleteUser)}
          onClose={() => setIdDeleteUser(null)}
          submitText="delete user"
          onSubmit={onDelete}
          isLoading={isLoading}
          loaderText="Deleting..."
        />
      </>
    </PageContent>
  );
};
