import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import s from "./Button.module.scss";
import cn from "classnames";
import { Icon } from "../Icon/Icon";

interface IButton
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  filled?: "empty" | "fill" | "gray" | "white" | "grayDark" | "invisible";
  size?: "xs" | "small" | "normal" | "large";
  wide?: "normal" | "long";
  uppercase?: boolean;
  icon?: string;
  title?: string;
  isLoading?: boolean;
  loader?: string;
}

export const Button: React.FC<IButton> = ({
  type = "button",
  filled = "fill",
  size = "normal",
  wide = "normal",
  uppercase = false,
  icon,
  title = "",
  className,
  isLoading = false,
  loader,
  disabled,
  ...rest
}) => {
  const classes = cn(
    s.btn,
    s[filled],
    s[size],
    { [`${s[wide]}`]: wide !== "normal" },
    uppercase && s.uppercase,
    isLoading && [s.loading],
    className
  );

  return (
    <button {...rest} type={type} className={classes} disabled={disabled}>
      {icon && <Icon icon={icon} className={s.icon} />}
      {title && !isLoading && <span className={s.title}>{title}</span>}
      {loader && isLoading && <span className={s.title}>{`${loader}`}</span>}
    </button>
  );
};
