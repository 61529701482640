import { Button, Icon, ModalAside, SortBtn, Table } from "components/common";
import { TableFilters } from "components/modules";
import { useSorting } from "hooks/useSorting";
import {
  ActionsColumn,
  HistoryActions,
  IColumnData,
  SortType,
  Sorting,
} from "interfaces";
import { useState, useMemo, useEffect, useContext } from "react";
import { ControlPanel } from "../ControlPanel/ControlPanel";
import s from "./TableControls.module.scss";
import { useFilter } from "hooks/useFilter";
import { SearchContext } from "context/SearchContext";
import { formatRowData } from "utils";
import { usePrevious } from "hooks/usePrevious";
import isEqual from "lodash/isEqual";

type Props = {
  columnsList: IColumnData[];
  list: any[];
  defaultSort?: Sorting;
  defaultSortedColumn?: string;
  defaultSorted?: boolean;
  withFilters?: boolean;
  searchColumns?: string[];
  actionsColumn: ActionsColumn[];
  idRowActionColumn: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onDownload?: (id: string) => void;
  tableAction?: JSX.Element;
  isLoading?: boolean;
};

export const TableControlsWithActionColumn = ({
  columnsList,
  list,
  defaultSort = Sorting.asc,
  defaultSortedColumn,
  defaultSorted,
  withFilters,
  searchColumns,
  actionsColumn,
  idRowActionColumn,
  onEdit,
  onDelete,
  onDownload,
  tableAction,
  isLoading,
}: Props) => {
  const [sortedData, setSortedData] = useState<any[]>([]);

  const [filteredData, setFilteredData] = useState(sortedData);
  const [searchedData, setSearchedData] = useState(filteredData);

  const [dataIsFiltered, setDataIsFiltered] = useState(false);
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  const { search } = useContext(SearchContext);

  const { sortColumn, sortingOrder, activeSorting, setActiveSorting } =
    useSorting(
      columnsList,
      setSortedData,
      defaultSort,
      defaultSorted ? defaultSortedColumn : undefined
    );

  const prevList = usePrevious(list);

  const prevSortedData = usePrevious(sortedData);

  const { isOpenFilter, toggleFilter, isStartFilter, setStartFilter } =
    useFilter();

  const actions = [
    {
      action: ActionsColumn.edit,
      label: "Edit",
      handleClick: onEdit ? (id: string) => onEdit(id) : () => {},
      icon: "edit",
    },
    {
      action: ActionsColumn.delete,
      label: "Delete",
      handleClick: onDelete ? (id: string) => onDelete(id) : () => {},
      icon: "trash",
    },
    {
      action: ActionsColumn.download,
      label: "Download",
      handleClick: onDownload ? (id: string) => onDownload(id) : () => {},
      icon: "download",
    },
  ];

  const columns = useMemo(() => {
    const dataColumns = columnsList.map(
      ({ name, label, label2, sortType, className }) => ({
        Header: (
          <div className={s.headCellWithSort}>
            <div>
              <span className={s.headCellLabel}>{label}</span>
              <span className={s.headCellLabel}>{label2}</span>
            </div>

            {sortType && (
              <SortBtn
                order={sortingOrder[name] || Sorting.asc}
                onClick={() => sortColumn(sortedData, name, sortType)}
                isActive={activeSorting === name}
              />
            )}
          </div>
        ),
        accessor: name,
        className,
      })
    );

    return [
      ...dataColumns,
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [sortingOrder, sortedData]);

  useEffect(() => {
    if (!isEqual(prevList, list)) {
      defaultSortedColumn &&
        sortColumn(
          list,
          defaultSortedColumn,
          columnsList.find(({ name }) => name === defaultSortedColumn)
            ?.sortType || SortType.alphabet
        );
      // setActiveSorting(
      //   defaultSorted && defaultSortedColumn ? defaultSortedColumn : ""
      // );
    }
  }, [list]);

  useEffect(() => {
    if (!isEqual(prevSortedData, sortedData)) {
      setFilteredData(sortedData);
    }
  }, [sortedData]);

  useEffect(() => {
    if (search.length) {
      const newData = filteredData.filter((row) => {
        return searchColumns?.find((item) =>
          row[item].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchedData(newData);
    } else setSearchedData(filteredData);
  }, [search, filteredData]);

  const onFilter = () => {
    setStartFilter(true);
    setDataIsFiltered(true);
  };

  const renderAction = (icon: string, text: string, onClick: () => void) => (
    <span key={icon} onClick={onClick} className={s.actionInCell}>
      <Icon icon={icon} />
      <span>{text}</span>
    </span>
  );

  const renderTableData = () => {
    return searchedData.map((row) => {
      const rowData = formatRowData(row, columnsList);
      const shouldRender = row?.action_type
        ? row.action_type === HistoryActions.download &&
          row.status === "Successful"
        : true;

      return {
        ...rowData,
        action: (
          <div className={s.actions}>
            {actions
              .filter((item) => actionsColumn.includes(item.action))
              .map(
                ({ icon, label, handleClick }) =>
                  shouldRender &&
                  renderAction(icon, label, () =>
                    handleClick(row[idRowActionColumn])
                  )
              )}
          </div>
        ),
      };
    });
  };

  return (
    <>
      <ControlPanel
        onOpenFilter={withFilters ? () => toggleFilter(true) : undefined}
        dataIsFiltered={dataIsFiltered}
        tableAction={tableAction}
        isLoading={isLoading}
      />
      <Table columns={columns} data={renderTableData()} />
      {withFilters && (
        <ModalAside
          isOpen={isOpenFilter}
          onClose={() => toggleFilter(false)}
          title="Filter"
          bottomBtn={
            <Button
              title="Filter"
              onClick={onFilter}
              wide="long"
              disabled={!filterIsChanged}
              uppercase
            />
          }
        >
          <TableFilters
            columnsList={columnsList}
            sortedData={sortedData}
            setFilteredData={setFilteredData}
            isStartFilter={isStartFilter}
            setStartFilter={setStartFilter}
            setDataIsFiltered={setDataIsFiltered}
            setFilterIsChanged={setFilterIsChanged}
          />
        </ModalAside>
      )}
    </>
  );
};
