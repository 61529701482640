import { historyActions } from "const/history";
import {formatDateTime, toJson} from "./common";
import { IUser } from "store/types/ManageUsers";
import { IHistoryItem } from "store/types/HistoryActions";

const formatFileName = (str: string): string => {
  const uuidRegex =
    /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}/gi;
  return str
    .replace(uuidRegex, "")
    .split("/")[1]
    .replace("-", " ")
    .replace(
      "3rd Party Comparison Report",
      "3rd Party Valuation Comparison Report"
    )
    .replace("3rd Party Reports", "3rd Party Valuation Report");
};

export const getDescription = (
  exception: string,
  type: string,
  cutDescription: boolean
) => {
  if (type.includes("pledge")) {
    const splittedContractNames = exception?.split(",");

    if (cutDescription && splittedContractNames?.length > 3) {
      const removedItems = splittedContractNames.splice(0, 3);
      return removedItems.join(", ") + " ...";
    }

    return splittedContractNames?.join(", ");
  }

  // const temp = "{'new_values': {'Contract ID': '2016152-CANC-1', 'Effective Date': '06/22/2016', 'Closing Date': '06/27/2016', 'Option Amount': '$ 90,000.00', 'Acquisition Fee': '$ 1,200.00', 'Assignment Cost': '$ 25,000.00', 'Tax & Title Cost': '$ 340.00', 'Purchase Price': '$ 116,540.00'}, 'old_values': {'Contract ID': '2016152-CANC-1', 'Effective Date': '06/22/2016', 'Closing Date': '06/27/2016', 'Option Amount': 90000.0, 'Acquisition Fee': 1300.0, 'Assignment Cost': 25000.0, 'Tax & Title Cost': '340.0', 'Purchase Price': 116640.0}}";
  // const asJson = toJson(temp.replaceAll("'", '"'));
  if (exception) {
    const asJson = toJson(exception.replaceAll("'", '"'));
    if (asJson) {
      try {
        const oldValues = asJson["old_values"] as string[];
        const new_values = asJson["new_values"] as string[];
        return [
          // @ts-ignore
          ["<b>Old Values</b>", ...Object.entries(oldValues).map(([k, v]) => `${k}: ${v}`)].join("\n"),
          // @ts-ignore
          ["<b>New Values</b>", ...Object.entries(new_values).map(([k, v]) => `${k}: ${v}`)].join("\n")
        ].join("\n\n");
      } catch (e) {
      }
    }
  }

  return exception;
};

export function capitalizeWords(str: string) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function getAction(action_type: string) {
  return historyActions.find(({ types }) => types.includes(action_type))
    ?.action;
}

export const getUsersOptions = (users: IUser[]) => {
  return users.map(({ id, first_name, last_name }) => ({
    key: id.toString(),
    label: `${first_name} ${last_name}`,
    value: id.toString(),
  }));
};

export const getHistoryList = (
  items: IHistoryItem[],
  cutDescription = false
) => {
  return items?.map((item) => ({
    id: item.id,
    dateFull: `${formatDateTime(item.created_date_time).date} ${
      formatDateTime(item.created_date_time).timeWithTimeZone
    }`,
    date: formatDateTime(item.created_date_time).date,
    time: formatDateTime(item.created_date_time).timeWithTimeZone,
    user: `${item.user.first_name} ${item.user.last_name}`,
    type: capitalizeWords(
      item.action_type.replaceAll("_", " ").replace("exists", "exits")
    ),
    action_type: getAction(item.action_type),
    file: item?.file?.s3_url
      ? formatFileName(item.file.s3_url)
      : getDescription(item.exception, item.action_type, cutDescription),
    fileId: item.file?.id,
    status: item.status.replace("Unknown", "Failed"),
  }));
};
