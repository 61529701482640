import cn from "classnames";
import s from "./PageContent.module.scss";
import { Header } from "../Header/Header";

type Props = {
  title?: string;
  path?: string;
  search?: boolean;
  history?: boolean;
  secondScreenMode?: boolean;
  onGoBack?: () => void;
  children: JSX.Element;
};

export const PageContent = ({
  title,
  path = "",
  search = false,
  history = true,
  secondScreenMode,
  onGoBack,
  children,
}: Props) => {
  return (
    <>
      {title && (
        <Header
          title={title}
          path={path}
          search={search}
          history={history}
          secondScreenMode={secondScreenMode}
          onGoBack={onGoBack}
        />
      )}
      <div className={cn(s.container, "container")}>{children}</div>
    </>
  );
};
