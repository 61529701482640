import React, { useState, useRef } from "react";
import { IOption } from "interfaces";
import { Icon } from "components/common";
import s from "./Select.module.scss";
import cn from "classnames";
import useClickOutside from "hooks/useClickOutside";

interface SelectProps {
  options: IOption[];
  onSelect: (value: string) => void;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  size?: "normal" | "xs";
  filled?: "" | "fill" | "gray" | "grayDark";
  minWidth?: "min-50" | "min-100" | "min-200" | "min-250" | undefined;
}

export const Select: React.FC<SelectProps> = ({
  options,
  onSelect,
  placeholder = "Select",
  value,
  disabled,
  size = "normal",
  filled = "",
  minWidth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (option: IOption) => {
    onSelect(option.value);
    handleClick();
  };

  useClickOutside(selectRef, isOpen, () => setIsOpen(false));

  return (
    <div className={s.container} ref={selectRef}>
      <div
        className={cn(
          "select",
          size === "xs" && s.xs,
          minWidth && s[minWidth],
          s.box,
          s[filled],
          disabled && s.disabled,
          disabled && "disabled",
          isOpen && s.open
        )}
        onClick={handleClick}
      >
        <span className={cn(s.value, !value && s.placeholder)}>
          {value
            ? options.find(({ value: val }) => value === val)?.label
            : placeholder}
        </span>
        <Icon icon="down" className={cn(s.arrow, isOpen && s.rotated)} />
      </div>
      {isOpen && (
        <div className={s.dropdown}>
          {options?.map((option: IOption) => (
            <div
              key={option.key}
              className={cn(s.option, value === option.value && s.active)}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
