import { Input, Button } from "components/common";
import { useAppSelector } from "hooks/reduxHooks";
import { useAction } from "hooks/useAction";
import { ChangeEvent, useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate, Link } from "react-router-dom";
import { emailValidator } from "utils";
import s from "./Auth.module.scss";
import { AuthRoute, DashboardsRoute } from "const";

export const Login = () => {
  const navigate = useNavigate();
  const { loginUser } = useAction();
  const { isLoading, error } = useAppSelector((state) => state.user);
  const cookies = new Cookies();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [validationErrors, setValidationErrors] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { email, password } = formValues;

  const handleFormChange = (value: string, field: string) => {
    if (validationErrors) setValidationErrors("");
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  useEffect(() => {
    const handleKeyDown = async (e: { key: string; }) => {
      if (e.key === 'Enter' && email && password) {
        await handleLogin();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [email, password]);

  const handleLogin = async () => {
    const isEmailValid = emailValidator(email);
    setValidationErrors(isEmailValid);
    if (!isEmailValid) {
      await loginUser(formValues);
      cookies.get("token") &&
        navigate(`${DashboardsRoute.SECURITIZATION}`, { replace: true });
    }
  };

  return (
    <div className={s.container}>
      <div className={s.heading}>
        <p className={s.h3}>Please log in to get started</p>
      </div>
      <div className={s.form}>
        <Input
          placeholder="Email"
          value={email}
          type="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFormChange(e.target.value, "email")
          }
          status={error || validationErrors ? "error" : "normal"}
          statusText={validationErrors || "Email or password is incorrect"}
          className={s.input}
          leftIcon="mail"
        />
        <Input
          placeholder="Password"
          type={passwordVisible ? "text" : "password"}
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFormChange(e.target.value, "password")
          }
          onClickIcon={() => setPasswordVisible(!passwordVisible)}
          rightIcon={passwordVisible ? "eye-closed" : "eye"}
          className={s.input}
          leftIcon="lock"
        />
        <Link className={s.forgotLink} to={AuthRoute.FORGOT_PASSWORD}>
          Forgot Password?
        </Link>
        <Button
          size="large"
          wide="long"
          title="LOG IN"
          onClick={handleLogin}
          disabled={!email.length || !password.length || isLoading}
          isLoading={isLoading}
          loader="Processing..."
          uppercase
        />
      </div>
    </div>
  );
};
