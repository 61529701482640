import { Cookies } from "react-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  FORGOT_PASSWORD,
  GET_USER_API,
  LOGIN_API,
  UPDATE_USER_API,
} from "const";
import {
  encodeDataToUrl,
  getQueryText,
  throwErrorIfRequestNotSucceed,
} from "utils";
import { fetchWithAuth } from "../../utils/fetch-with-auth";

const cookies = new Cookies();

const loginUser = createAsyncThunk(
  "auth/login",
  async (_payload: { email: string; password: string }, thunkApi) => {
    try {
      const { email, password } = _payload;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      const response = await fetch(LOGIN_API, {
        method: "post",
        body: formData,
      });

      const { user_data, token, refresh_token } = await response.json();
      throwErrorIfRequestNotSucceed(response);
      cookies.set("token", token, { path: "/" });
      cookies.set("refresh_token", refresh_token, { path: "/" });
      cookies.set("email", email, { path: "/" });

      return thunkApi.fulfillWithValue({
        firstName: user_data?.first_name,
        lastName: user_data?.last_name,
        email: user_data?.email,
        phone: user_data?.phone_number,
        role: user_data?.role,
      });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (_payload: { email: string }, thunkApi) => {
    //todo: waiting for a backend
    // try {
    //   const { email } = _payload;
    //   const formData = new FormData();
    //   formData.append("email", email);
    //   const response = await fetchWithAuth(FORGOT_PASSWORD, {
    //     method: "post",
    //     body: formData,
    //   });
    //   await response.json();
    //   throwErrorIfRequestNotSucceed(response);
    // } catch (err) {
    //   return thunkApi.rejectWithValue(err);
    // }
  }
);

const updateUser = createAsyncThunk(
  "kwm/user",
  async (
    _payload: {
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      role?: string;
    },
    thunkApi
  ) => {
    try {
      const { email, first_name, last_name, phone_number, role } = _payload;
      const queryText = encodeDataToUrl({
        email,
        first_name,
        last_name,
        phone_number,
        role,
      });

      const response = await fetchWithAuth(`${UPDATE_USER_API}?${queryText}`, {
        method: "put",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();
      const { user_data } = parsed;

      throwErrorIfRequestNotSucceed(response);

      return thunkApi.fulfillWithValue({
        firstName: user_data?.first_name,
        lastName: user_data?.last_name,
        email: user_data?.email,
        phone: user_data?.phone_number,
        role: user_data?.role,
      });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const getUser = createAsyncThunk(
  "kwm/user",
  async (_payload: { email: string }, thunkApi) => {
    try {
      const { email } = _payload;
      const queryText = getQueryText([{ name: "email", value: email }]);

      const response = await fetchWithAuth(`${GET_USER_API}?${queryText}`, {
        method: "get",
        headers: {
          authorization: "Bearer " + cookies.get("token"),
        },
      });
      const parsed = await response.json();
      const { first_name, last_name, phone_number, role, id } = parsed;

      throwErrorIfRequestNotSucceed(response);

      return thunkApi.fulfillWithValue({
        firstName: first_name,
        lastName: last_name,
        email,
        phone: phone_number,
        role,
        id,
      });
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export { loginUser, forgotPassword, updateUser, getUser };
