import {
  allKasfIdsKey,
  allKasfIdsLabel,
  allPoolIdsKey,
  allPoolIdsLabel,
} from "./constants";

export const ORIGINATOR_OPTIONS_BASE = [
  { key: "all", label: "All", value: "All" },
  { key: "point", label: "Point", value: "Point" },
  {
    key: "splitero",
    label: "Splitero",
    value: "Splitero",
  },
];

export const ORIGINATOR_OPTIONS_EXTENDED = [
  ...ORIGINATOR_OPTIONS_BASE,
  { key: "splitero-es", label: "Splitero-ES", value: "Splitero-ES" },
];

export const ORIGINATOR_OPTIONS_FOR_BASE = [
  { key: "point kasf", label: "Point KASF", value: "Point KASF", poolId: 1 },
  // { key: "point other", label: "Point Other", value: "Point Other", poolId: 5 },
  {
    key: "splitero kasf",
    label: "Splitero KASF",
    value: "Splitero KASF",
    poolId: 11,
  },
  {
    key: "splitero other",
    label: "Splitero Other",
    value: "Splitero Other",
    poolId: 12,
  },
  { key: "sp-1", label: "SP-1", value: "SP-1", poolId: 7 },
  {
    key: "pnt 2021-1 securitization",
    label: "PNT 2021-1 Securitization",
    value: "PNT 2021-1 Securitization",
    poolId: 8,
  },
  {
    key: "pnt 2023-2 securitization",
    label: "PNT 2023-2 Securitization",
    value: "PNT 2023-2 Securitization",
    poolId: 14,
  },
  {
    key: "pnt 2024-1 securitization",
    label: "PNT 2024-1 Securitization",
    value: "PNT 2024-1 Securitization",
    poolId: 15,
  },
];

export const ORIGINATOR_OPTIONS_FOR_MODELS = [
  { key: "all", label: "All", value: "All", poolId: 13 },
  ...ORIGINATOR_OPTIONS_FOR_BASE,
];

export const ORIGINATOR_OPTIONS_FOR_MODELS_WITH_ALL = [
  { key: allPoolIdsKey, label: allPoolIdsLabel, value: allPoolIdsLabel },
  { key: allKasfIdsKey, label: allKasfIdsLabel, value: allKasfIdsKey },
  ...ORIGINATOR_OPTIONS_FOR_BASE,
];

export const INTERNAL_VALUATION_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: "sp-1", label: "SP-1", value: "SP-1" },
];

export const LIST_TYPE_OPTIONS = [
  { key: "asset_list", label: "Asset List", value: "Asset List" },
  {
    key: "internal_valuation",
    label: "Valuation List",
    value: "Valuation List",
  },
];

export const SEASONING_MONTHS = [
  { key: "all", label: "All", value: "All" },
  { key: "1_month", label: "1 Month", value: "1 Month" },
  { key: "2_months", label: "2 Months", value: "2 Months" },
  { key: "3_months", label: "3 Months", value: "3 Months" },
  { key: "4_months", label: "4 Months", value: "4 Months" },
  { key: "5_months", label: "5 Months", value: "5 Months" },
  { key: "6_months", label: "6 Months", value: "6 Months" },
  { key: "7_months", label: "7 Months", value: "7 Months" },
  { key: "8_months", label: "8 Months", value: "8 Months" },
  { key: "9_months", label: "9 Months", value: "9 Months" },
  { key: "10_months", label: "10 Months", value: "10 Months" },
  { key: "11_months", label: "11 Months", value: "11 Months" },
  { key: "12_months", label: "12 Months", value: "12 Months" },
];

export const MONTH_NAMES = [
  { key: "01", label: "January", value: "January" },
  { key: "02", label: "February", value: "February" },
  { key: "03", label: "March", value: "March" },
  { key: "04", label: "April", value: "April" },
  { key: "05", label: "May", value: "May" },
  { key: "06", label: "June", value: "June" },
  { key: "07", label: "July", value: "July" },
  { key: "08", label: "August", value: "August" },
  { key: "09", label: "September", value: "September" },
  { key: "10", label: "October", value: "October" },
  { key: "11", label: "November", value: "November" },
  { key: "12", label: "December", value: "December" },
];

export const PLEDGE_TO_COLLATERAL = [
  {
    key: "PNT 10-2022",
    label: "Point Digital Finance, LLC",
    value: "Point Digital Finance, LLC",
  },
  {
    key: "EWB 02-2023",
    value: "East West Bank",
    label: "East West Bank",
  },
];

export const DEBT_LINE = [
  { key: "PNT 10-2022", value: "PNT 10-2022", label: "PNT 10-2022" },
  { key: "EWB 02-2023", value: "EWB 02-2023", label: "EWB 02-2023" },
];

export const AMOUNT_FILTER_OPTIONS = [
  { key: "1", value: "0_9999", label: "0 - 9 999", from: -Infinity, to: 9999 },
  {
    key: "2",
    value: "10000_49999",
    label: "10 000 - 49 999",
    from: 10000,
    to: 49999,
  },
  {
    key: "3",
    value: "over50000",
    label: "over 50 000",
    from: 50000,
    to: Infinity,
  },
];

export const INVESTMENT_TERM_FILTER = [
  { key: "0", label: "", value: "" },
  { key: "2", label: "2", value: "2" },
  { key: "10", label: "10", value: "10" },
  { key: "30", label: "30", value: "30" },
];

export const PLEDGE_LINE_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  {
    key: "availableToPledge",
    label: "Available to Pledge",
    value: "Available to Pledge",
  },
  {
    key: "EastWestBank",
    value: "East West Bank",
    label: "East West Bank",
  },
];

export const FILING_TYPE_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: "bankruptcy", label: "Bankruptcy", value: "Bankruptcy" },
  {
    key: "foreclosure",
    label: "Foreclosure",
    value: "Foreclosure",
  },
];

export const SEARCH_BY_OPTIONS = [
  { key: "contract_ids", label: "Contract ID", value: "Contract ID" },
  { key: "service_numbers", label: "Service Number", value: "Service Number" },
  { key: "addresses", label: "Address", value: "Address" },
];

export const STATUS_CONTRACT_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: "active", label: "Active", value: "Active" },
  {
    key: "exited",
    label: "Exited",
    value: "Exited",
  },
  {
    key: "expired",
    label: "Expired",
    value: "Expired",
  },
];

export const LENDER_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  {
    key: "PNT 10-2022",
    label: "Point Digital Finance, LLC",
    value: "Point Digital Finance, LLC",
  },
  {
    key: "EWB 02-2023",
    value: "East West Bank",
    label: "East West Bank",
  },
];

export const FICO_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: ">= 500", value: ">= 500", label: ">= 500" },
  { key: ">= 550", value: ">= 550", label: ">= 550" },
  { key: ">= 600", value: ">= 600", label: ">= 600" },
  { key: ">= 650", value: ">= 650", label: ">= 650" },
  { key: ">= 700", value: ">= 700", label: ">= 700" },
  { key: ">= 750", value: ">= 750", label: ">= 750" },
  { key: ">= 800", value: ">= 800", label: ">= 800" },
];

export const LOTV_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: "<= 85%", value: "<= 85%", label: "<= 85%" },
  { key: "<= 75%", value: "<= 75%", label: "<= 75%" },
  { key: "<= 70%", value: "<= 70%", label: "<= 70%" },
  { key: "<= 65%", value: "<= 65%", label: "<= 65%" },
  { key: "<= 60%", value: "<= 60%", label: "<= 60%" },
  { key: "<= 55%", value: "<= 55%", label: "<= 55%" },
  { key: "<= 50%", value: "<= 50%", label: "<= 50%" },
];

export const RISK_ADJUSTMENT_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: ">= 15%", value: ">= 15%", label: ">= 15%" },
  { key: ">= 20%", value: ">= 20%", label: ">= 20%" },
  { key: ">= 25%", value: ">= 25%", label: ">= 25%" },
  { key: ">= 30%", value: ">= 30%", label: ">= 30%" },
];

export const MULTIPLE_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: ">= 1.0", value: ">= 1.0", label: ">= 1.0" },
  { key: ">= 1.5", value: ">= 1.5", label: ">= 1.5" },
  { key: ">= 2.0", value: ">= 2.0", label: ">= 2.0" },
  { key: ">= 2.5", value: ">= 2.5", label: ">= 2.5" },
  { key: ">= 3.0", value: ">= 3.0", label: ">= 3.0" },
  { key: ">= 3.5", value: ">= 3.5", label: ">= 3.5" },
  { key: ">= 4.0", value: ">= 4.0", label: ">= 4.0" },
  { key: ">= 4.5", value: ">= 4.5", label: ">= 4.5" },
  { key: ">= 5.0", value: ">= 5.0", label: ">= 5.0" },
];

export const LIEN_POSITION_OPTIONS = [
  { key: "all", label: "All", value: "All" },
  { key: "<= 1st Lien", value: "<= 1st Lien", label: "<= 1st Lien" },
  { key: "<= 2nd Lien", value: "<= 2nd Lien", label: "<= 2nd Lien" },
  { key: "<= 3rd Lien", value: "<= 3rd Lien", label: "<= 3rd Lien" },
];

export const POOL_OPTIONS = [
  { key: "all", label: "All", value: "all" },
  { key: "all point", label: "All Point", value: "all point" },
  { key: "kasf point", label: "KASF Point", value: "kasf point" },
  { key: "other point", label: "Other Point", value: "other point" },
  {
    key: "all splitero",
    label: "All Splitero",
    value: "all splitero",
  },
  {
    key: "kasf splitero",
    label: "KASF Splitero",
    value: "kasf splitero",
  },
  { key: "other splitero", label: "Other Splitero", value: "other splitero" },
  {
    key: "pnt 2021-1",
    label: "PNT 2021-1 Securitization",
    value: "pnt 2021-1",
  },
  {
    key: "pnt 2024-1",
    label: "PNT 2024-1 Securitization",
    value: "pnt 2024-1",
  },
  {
    key: "pnt 2023-2",
    label: "PNT 2023-2 Securitization",
    value: "pnt 2023-2",
  },
];

export const POOL_OPTIONS_2 = [
  { key: "all kingsbridge", label: "All Kingsbridge", value: "all kingsbridge" },
  { key: "kasf point", label: "Direct Point", value: "kasf point" },
  { key: "kasf splitero", label: "Direct Splitero", value: "kasf splitero" },
  { key: "sp-1", label: "SP-1", value: "sp-1" },
  { key: "other splitero", label: "Other Splitero", value: "other splitero" },
  { key: "other point", label: "Other Point", value: "other point" },
];

export const CONTRACTS_OPTIONS = [
  { key: "all", label: "All", value: "all" },
  { key: "individuals", label: "Individuals", value: "individuals" },
];

export const ALL_YEARS_TO_OPTIONS = [
  { key: "all", label: "All", value: "all" },
];

export const HPA_YEARS_FROM_OPTIONS = [
  { key: "effective_year", label: "Effective Date", value: "effective_year" },
];

export const HPA_YEARS_TO_OPTIONS = [
  ...ALL_YEARS_TO_OPTIONS,
  { key: "effective_year", label: "Effective Date", value: "effective_year" },
];

export const REPORT_TYPE_OPTIONS = [
  { key: "quarterly", label: "Quarterly", value: "quarterly" },
  {
    key: "effective_latest",
    label: "Effective/Latest",
    value: "effective_latest",
  },
];

export const AREA_OPTIONS = [
  { key: "metro", label: "Metro", value: "metro" },
  { key: "non_metro", label: "Non-Metro", value: "non_metro" },
]

export const STATE_OPTIONS = [
  { key: "all", label: "All", value: "all" },
  { key: "AK", label: "AK", value: "AK" },
  { key: "AL", label: "AL", value: "AL" },
  { key: "AR", label: "AR", value: "AR" },
  { key: "AZ", label: "AZ", value: "AZ" },
  { key: "CA", label: "CA", value: "CA" },
  { key: "CO", label: "CO", value: "CO" },
  { key: "CT", label: "CT", value: "CT" },
  { key: "DC", label: "DC", value: "DC" },
  { key: "DE", label: "DE", value: "DE" },
  { key: "FL", label: "FL", value: "FL" },
  { key: "GA", label: "GA", value: "GA" },
  { key: "HI", label: "HI", value: "HI" },
  { key: "IA", label: "IA", value: "IA" },
  { key: "ID", label: "ID", value: "ID" },
  { key: "IL", label: "IL", value: "IL" },
  { key: "IN", label: "IN", value: "IN" },
  { key: "KS", label: "KS", value: "KS" },
  { key: "KY", label: "KY", value: "KY" },
  { key: "LA", label: "LA", value: "LA" },
  { key: "MA", label: "MA", value: "MA" },
  { key: "MD", label: "MD", value: "MD" },
  { key: "ME", label: "ME", value: "ME" },
  { key: "MI", label: "MI", value: "MI" },
  { key: "MN", label: "MN", value: "MN" },
  { key: "MO", label: "MO", value: "MO" },
  { key: "MS", label: "MS", value: "MS" },
  { key: "MT", label: "MT", value: "MT" },
  { key: "ND", label: "ND", value: "ND" },
  { key: "NE", label: "NE", value: "NE" },
  { key: "NH", label: "NH", value: "NH" },
  { key: "NJ", label: "NJ", value: "NJ" },
  { key: "NM", label: "NM", value: "NM" },
  { key: "NV", label: "NV", value: "NV" },
  { key: "NY", label: "NY", value: "NY" },
  { key: "OH", label: "OH", value: "OH" },
  { key: "OK", label: "OK", value: "OK" },
  { key: "OR", label: "OR", value: "OR" },
  { key: "PA", label: "PA", value: "PA" },
  { key: "PR", label: "PR", value: "PR" },
  { key: "RI", label: "RI", value: "RI" },
  { key: "SC", label: "SC", value: "SC" },
  { key: "SD", label: "SD", value: "SD" },
  { key: "TN", label: "TN", value: "TN" },
  { key: "TX", label: "TX", value: "TX" },
  { key: "UT", label: "UT", value: "UT" },
  { key: "VA", label: "VA", value: "VA" },
  { key: "VT", label: "VT", value: "VT" },
  { key: "WA", label: "WA", value: "WA" },
  { key: "WI", label: "WI", value: "WI" },
  { key: "WV", label: "WV", value: "WV" },
  { key: "WY", label: "WY", value: "WY" },
]

export const FILLING_TYPE_OPTIONS = [
  { key: "bankruptcy", label: "Bankruptcy", value: "bankruptcy" },
  { key: "foreclosure", label: "Foreclosure", value: "foreclosure" },
]

export const PRODUCT_TYPE = [
  { key: "all", label: "All", value: "all" },
  { key: "AS", label: "Appreciation Share", value: "AS" },
  { key: "ES", label: "Equity Share", value: "ES" },
]

export const CONTRACT_TERM = [
  { key: "all", label: "All", value: "all" },
  { key: "10", label: "10", value: "10" },
  { key: "30", label: "30", value: "30" },
]

export const CONTRACT_STATUS = [
  { key: "all", label: "All", value: "all" },
  { key: "active", label: "Active", value: "active" },
  { key: "active_pending_funded", label: "Active + Pending Funded", value: "active_pending_funded" },
  { key: "active_pending_funded_pending_unfunded", label: "Active + Pending Funded + Pending Unfunded", value: "active_pending_funded_pending_unfunded" },
]

export const FEE_TYPE = [
  { key: "actual", label: "Actual", value: "actual" },
  { key: "flat", label: "Flat", value: "flat" },
]